<template>
  <div id="delivery-setting">
    <v-data-table
      :headers="header"
      :items="shippingData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :height="530"
      :search="inputSearch"
      class="elevation-1 mb-5"
      hide-default-footer
      @page-count="pageCount = $event">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Delivery Setting
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-text-field
            ref="searchTextField"
            v-model="inputSearch"
            append-icon="mdi-magnify"
            class="mb-2 mr-5 search-text-field"
            label="Search"
            single-line
            hide-details
            @input="searchAttribute(true, $event)" />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.providerName"
                          :rules="rules.textFieldRules"
                          :readonly="modalLoading"
                          label="Provider Name" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.method"
                          :rules="rules.textFieldRules"
                          :readonly="modalLoading"
                          label="Method" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.price"
                          :rules="rules.priceRules"
                          :readonly="modalLoading"
                          label="Price" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.note"
                          :rules="rules.textFieldRules"
                          :readonly="modalLoading"
                          label="Note" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeDeivery(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ShippingProvider from '@/resources/ShippingProvider'

const shippingService = new ShippingProvider()

export default {
  data () {
    return {
      inputSearch: '',
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'Provider Name',
          value: 'providerName',
          width: 100
        },
        {
          text: 'Method',
          value: 'method',
          width: 100
        },
        {
          text: 'Price',
          value: 'price',
          width: 100
        },
        {
          text: 'Note',
          value: 'note',
          width: 200
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      shippingData: [],
      isEdited: false,
      formData: {},
      rules: {
        textFieldRules: {
          required: true
        },
        priceRules: {
          required: true,
          integer: true,
          min_value: 0
        }
      },
      getting: false,
      modalLoading: false
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    search () {
      return this.$route.query.search ? this.$route.query.search : ''
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  created () {
    this.getShipping()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    setPageCount (event) {
      this.pageCount = event
    },
    capitalize (value) {
      if (!value) {
        return ''
      }
      return value.toString().charAt(0).toUpperCase() + value.slice(1)
    },
    async getShipping () {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await shippingService.getShipping()
          this.shippingData = data
        } catch (error) {
          console.error('getShipping: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = {}
    },
    editItem (item) {
      this.isEdited = true
      this.formData = {
        id: item.id,
        providerName: item.providerName,
        method: item.method,
        price: item.price,
        note: item.note
      }
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.attributeForm.validate()
      if (vaid) {
        let isSuscess = false
        let message = 'created'
        try {
          if (this.isEdited) {
            isSuscess = await this.updateShipping(this.formData)
            message = 'updated'
          } else {
            isSuscess = await this.createShipping(this.formData)
          }
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
          console.error('saveAttribute', error)
        } finally {
          this.closeFormModal()
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t(`snackbar.Delivery ${message}`),
              type: 'success'
            })
            this.getShipping()
          }
        }
      }
      this.modalLoading = false
    },
    async createShipping (item) {
      const payload = { ...item, price: parseInt(item.price) }
      const { data } = await shippingService.createShipping(payload)
      return !!data.id
    },
    async updateShipping (item) {
      const payload = { ...item, price: parseInt(item.price) }
      const { data } = await shippingService.updateShipping(item.id, payload)
      return !!data.id
    },
    async removeDeivery (item) {
      this.setModal({
        value: true,
        title: 'RemoveDelivery',
        message: 'Do you want to remove the delivery',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveDelivery(item.id)
      })
    },
    async confirmRemoveDelivery (id) {
      try {
        const { data } = await shippingService.deleteShipping(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.Delivery removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.shippingData.length < 2) {
            this.options.page--
          } else {
            this.getShipping()
          }
        } else {
          const error = {
            code: 400,
            message: 'Shipping ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveDelivery', error)
      }
    }
  }
}
</script>
